import React from 'react';
import { Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Divider, CardMedia, Container } from '@mui/material';
import { styled } from '@mui/system';
import MissionIcon from '@mui/icons-material/Flag';
import ValuesIcon from '@mui/icons-material/People';
import ContactForm from '../components/ContactForm';
import HeroSection from '../components/HeroSection';
import SectionTitle from '../components/SectionTitle';
import WebpGallery from '../components/WebpGallery';
import WebpGallery2 from '../components/WebpGallery2';
import { Helmet } from 'react-helmet-async';

const SectionTitle2 = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  fontSize: '2.5rem',
  fontWeight: 'bold',
  fontFamily: 'Oswald, sans-serif',
}));

const ContentText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.secondary,
  lineHeight: 1.6,
  fontSize: '1.1rem',
  background: 'rgba( 0, 0, 0, 0.5)',
  padding: 20,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: 10,
}));

const SectionGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(5),
  borderRadius: '16px',
  boxShadow: theme.shadows[6],
  backgroundColor: theme.palette.background.default,
  textAlign: 'center',
}));

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About Us | Living Faith Christian Fellowship</title>
        <meta name="description" content="About Living Faith Christian Fellowship. Our vision is to be a life-giving church to the Filipino, American, and International community." />
        <link rel="canonical" href="https://livingfaithchristianfellowship.com/about" />
      </Helmet>
      <HeroSection videoSrc='https://ymnvmmsnzwnwmaabghnu.supabase.co/storage/v1/object/public/Living%20Faith%20Christian%20Fellowship%20Media/LFCF-IMAGES/Hero%20Video/3.mp4' title="About Us" scrollToId="#AboutUs" />
      <Container maxWidth="md" sx={{ paddingTop: 8, paddingBottom: 6 }}>
        <SectionTitle variant='h2'>
          Our Mission
        </SectionTitle>
        <Divider sx={{ marginBottom: 4 }} />
        
        <CardMedia  
          component="img"
          height="400"
          image='https://ymnvmmsnzwnwmaabghnu.supabase.co/storage/v1/object/public/Living%20Faith%20Christian%20Fellowship%20Media/LFCF-IMAGES/Misc/1.webp?t=2024-10-03T00%3A31%3A10.385Z'
          alt="Living Faith Christian Fellowship"
          sx={{ maxWidth: '700px', margin: '0 auto', marginBottom: 6, borderRadius: 3, boxShadow: 3 }}
        />
        
        <SectionGrid container spacing={6}>
          <Grid item xs={12} md={10}>
            <StyledPaper>
              <SectionTitle2 id="AboutUs" variant="h4">
                Mission Statement
              </SectionTitle2>
              <Divider sx={{ marginBottom: 2 }} />
              <ContentText>
                Our vision is to be a life-giving church to the Filipino, American, and International community.
              </ContentText>
              <Divider sx={{ marginBottom: 2 }} />
              <List>
                <ListItem>
                  <ListItemIcon>
                    <MissionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="To give Joy to our God" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MissionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="To Equip believers" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MissionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="To Serve God" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MissionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="To bring Unity" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MissionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="To Spread the Gospel" />
                </ListItem>
              </List>
            </StyledPaper>
          </Grid>     
          
          <Grid item xs={12} md={10}>
            <StyledPaper>
              <SectionTitle2 variant="h4">
                Church Values
              </SectionTitle2>
              <Divider sx={{ marginBottom: 2 }} />
              <List>
                <ListItem>
                  <ListItemIcon>
                    <ValuesIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Faith: Trusting in God's plan and His timing." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ValuesIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Community: Building strong, supportive relationships." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ValuesIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Service: Demonstrating God's love through actions." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ValuesIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Grace: Showing compassion and forgiveness." />
                </ListItem>
              </List>
            </StyledPaper>
          </Grid>
        </SectionGrid>  
      </Container>

      {/* Ensure the gallery has enough space below */}
      <div className="padding-bottom-pages">
        <WebpGallery />
        <WebpGallery2 />
      </div>

     
        <ContactForm />
     
    </div>
  );
};

export default About;
