import React from 'react';
import { Divider } from '@mui/material';
import EventGrid from '../components/EventGrid';
import ContactForm from '../components/ContactForm';
import HeroSection from '../components/HeroSection';
import SectionTitle from '../components/SectionTitle';
import CalendarView from '../components/CalendarView';
import { Helmet } from 'react-helmet-async';

const Schedule = () => {
 
  return (
    <div>
      <Helmet>
        <title>Schedule | Living Faith Christian Fellowship</title>
        <meta name="description" content="Check out the weekly schedule for Living Faith Christian Fellowship, including upcoming events and a full calendar." />
        <link rel="canonical" href="https://livingfaithchristianfellowship.com/schedule" />
      </Helmet>
      <HeroSection
        videoSrc='https://ymnvmmsnzwnwmaabghnu.supabase.co/storage/v1/object/public/Living%20Faith%20Christian%20Fellowship%20Media/LFCF-IMAGES/Hero%20Video/4.mp4?t=2024-10-03T00%3A52%3A50.632Z'
        title="Our Schedule"
        scrollToId="#OurSchedule"
      />
      
      <SectionTitle variant="h2">
        Every Week
      </SectionTitle>
      <Divider sx={{ marginBottom: 5 }} />
      <EventGrid />
      <SectionTitle id="OurSchedule" variant="h2">
        CALENDAR
      </SectionTitle>
      <Divider sx={{ marginBottom: 5 }} />
      <div className="padding-bottom-pages">
        <CalendarView />
      </div>
      <ContactForm />
    </div>
  );
};

export default Schedule;
