import React from 'react';

const WebpGallery = () => {
  const imageNumbers = Array.from({ length: 8 }, (_, i) => i + 8);

  const galleryStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    gap: '10px',
    padding: '20px',
  };
  
  const imageStyle = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '8px',
  };

  return (
    <div style={galleryStyle}>
      {imageNumbers.map((number) => (
        <img
          key={number}
          src={`https://ymnvmmsnzwnwmaabghnu.supabase.co/storage/v1/object/public/Living%20Faith%20Christian%20Fellowship%20Media/LFCF-IMAGES/Misc/${number}.webp?t=2024-10-03T00%3A33%3A25.629Z`}
          alt="Living Faith Christian Fellowship Virginia Beach Virginia Hampton Roads Church"
          style={imageStyle}
          loading="lazy"
        />
      ))}
    </div>
  );
};

export default WebpGallery;
